@import url('https://fonts.googleapis.com/css?family=Abel|Cinzel');

$primary: #191330;/* MAIN COLOR */
$secondary: #00aeef; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-family: 'Abel', sans-serif;

    p{
       font-family: 'Abel', sans-serif;
       font-size: 16px;
    }

    h1,h2,h3,h4 {
      font-family: 'Cinzel', serif;
    }
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #000;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    background-color: #fff;
    border-radius: $border-radius;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
          border-radius: $border-radius;
          font-family: 'Cinzel', serif;
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $primary;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.7em 2em;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
          -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/
// section star
.section-a {
  padding: 100px 0;
  background-color: $primary;
  background-image: url("../img/pattern.png");
  background-repeat: repeat;
  h2 {
    font-size: 2em;
  }
  .txt {
    margin-top:5%;
    font-size: 1.5em;
    color: $wht;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
    @media(max-width: 767px){
      font-size: 1em;
    }
  }
  @media(max-width: 991px){
    padding: 50px 0;
  }
  @media(max-width: 767px){
    padding: 20px 0;
  }
}
.section-b{
  background-image: url('../img/bg1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 250px 0;
  @media(max-width: 991px){
    padding: 200px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 600px){
    padding: 100px 0;
  }
  @media(max-width: 460px){
    padding: 50px 0;
  }
  .col-md-12{
    background: rgba(255, 255, 255, .7);
    padding: 25px;
  }
}
.section-c{
  background-color: $primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 250px 0;
  color: $wht;
  @media(max-width: 991px){
    padding: 200px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 600px){
    padding: 100px 0;
  }
  @media(max-width: 460px){
    padding: 50px 0;
  }

}
.section-d{
  background-image: url('../img/bg2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 250px 0;
  @media(max-width: 991px){
    padding: 200px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 600px){
    padding: 100px 0;
  }
  @media(max-width: 460px){
    padding: 50px 0;
  }
  .col-md-12{
    background: rgba(255, 255, 255, .7);
    padding: 25px;
  }
}

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 1em 0em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em 0em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }

    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}

@media (max-width: 896px) and (orientation: landscape){
  .modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 0em auto;
  }
}